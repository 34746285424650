import React, { useEffect, useCallback, useState } from 'react';
import AnswerOptions from './AnswerOptions';
import { QuestionDisplay } from './quiz/QuestionDisplay';
import { ResultDisplay } from './quiz/ResultDisplay';
import { useQuizContext } from './quiz/state/QuizContext';
import { useAuth } from '../contexts/AuthContext';
import { TimeUtils } from '../utils/adaptiveLearning';
import { useQuizTimer } from '../hooks/useQuizTimer';
import QuizSummary  from './QuizSummary';
import { useNavigate } from 'react-router-dom';
import { logger } from '../utils/logger';
import { getCategoryBreakdown } from '../utils/quiz/core';

interface QuizComponentProps {
  onAnswerSelect: (answer: string, questionTime: number) => void;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
  selectedAnswer: string | null;
  showResult: boolean;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
  isSubscriber: boolean;
  showContinueButton: boolean;
  continueButtonText: string;
}

const QuizComponentBase = ({
  showHint,
  setShowHint,
  selectedAnswer,
  showResult,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue,
  isSubscriber,
  showContinueButton,
  continueButtonText
}: Omit<QuizComponentProps, 'quizProgress'>) => {
  const { userData } = useAuth();
  const { state, dispatch } = useQuizContext();
  const { timeSpent, startTimer, stopTimer, resetTimer } = useQuizTimer();
  const navigate = useNavigate();
  // const [showResult, setShowResult] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  // Get quiz progress from context
  const quizProgress = state.progress;
  if (!quizProgress) {
    throw new Error('Quiz progress not found in context');
  }
  
  // Get adaptive state from quiz progress
  const adaptiveState = quizProgress.adaptiveState ?? {
    difficulty: 5,
    categoryStrengths: {},
    insights: {
      weakCategories: [],
      performanceTrend: 'steady',
      recommendedDifficulty: 5
    }
  };
  
  // Get current question with completion check
  const currentQuestion = React.useMemo(() => {
    if (!quizProgress) return null;
    
    // Add logging for completion check
    logger.debug('QuizComponent', 'Checking completion status', {
      completed: quizProgress.completed,
      currentIndex: quizProgress.currentQuestionIndex,
      totalQuestions: quizProgress.questions.length
    });
    
    if (quizProgress.completed || 
        quizProgress.currentQuestionIndex >= quizProgress.questions.length) {
      return null;
    }
    
    const question = quizProgress.questions[quizProgress.currentQuestionIndex];
    if (!question) return null;

    return {
      ...question,
      difficulty: adaptiveState.currentDifficulty,
      recommendedTime: TimeUtils.getExpectedTime(adaptiveState.currentDifficulty)
    };
  }, [quizProgress, adaptiveState]);
  // Add after currentQuestion memo
  useEffect(() => {
    if (currentQuestion && !selectedAnswer && !showResult) {
      resetTimer();
      startTimer();
      
      dispatch({
        type: 'UPDATE_QUESTION_START_TIME',
        payload: Date.now()
      });
    }
  }, [currentQuestion?.id, selectedAnswer, showResult, resetTimer, startTimer, dispatch]);

  // Replace the timestamp logging effect with this version
  useEffect(() => {
    // Only log on meaningful state changes
    if (!currentQuestion) return;

    const timestamp = new Date().toISOString();
    logger.debug('QuizComponent', 'Dependencies changed', {
      timestamp,
      currentQuestionId: currentQuestion.id,
      selectedAnswer,
      showResult,
      currentIndex: state.progress?.currentQuestionIndex
    });

    return () => {
      logger.debug('QuizComponent', 'Cleanup effect');
    };
  }, [currentQuestion?.id, selectedAnswer, showResult, state.progress?.currentQuestionIndex]);

  // Handle answer selection
  const handleAnswerClick = React.useCallback((answer: string) => {
    // if (isTransitioning) return;
    // setIsTransitioning(true);
    if (!showResult && !selectedAnswer && currentQuestion) {
      const questionTime = stopTimer();
      
      logger.debug('QuizComponent', 'Answer selected', {
        questionId: currentQuestion.id,
        timeSpent: questionTime,
        isLastQuestion: quizProgress?.currentQuestionIndex === quizProgress?.questions.length - 1
      });
      
      dispatch({ 
        type: 'SET_ANSWER_WITH_TIME', 
        payload: {
          answer,
          timeSpent: questionTime,
          questionId: currentQuestion.id
        }
      });
    }

    // setShowResult(true);
  }, [showResult, selectedAnswer, currentQuestion, stopTimer, dispatch]);
  
  const handleContinue = useCallback(async () => {
    try {
      const isLastQuestion = quizProgress.currentQuestionIndex === quizProgress.questions.length - 1;
      
      logger.debug('QuizComponent', 'Handle continue clicked', {
        isLastQuestion,
        currentIndex: quizProgress.currentQuestionIndex,
        totalQuestions: quizProgress.questions.length
      });

      if (isLastQuestion && showResult) {
        // Calculate final score and dispatch completion first
        const categoryBreakdown = getCategoryBreakdown(quizProgress);
        
        dispatch({ 
          type: 'QUIZ_COMPLETE', 
          payload: {
            timestamp: Date.now(),
            finalScore: quizProgress.score,
            adaptiveState: quizProgress.adaptiveState,
            summary: {
              totalQuestions: quizProgress.questions.length,
              answeredQuestions: quizProgress.questions.length,
              correctAnswers: quizProgress.score,
              wrongAnswers: quizProgress.wrongAnswers.length,
              score: quizProgress.score,
              completionPercentage: (quizProgress.score / quizProgress.questions.length) * 100,
              categoryBreakdown
            }
          }
        });

        // Don't call handleSaveAndContinue for the last question
        return;
      }

      // Only call handleSaveAndContinue for non-last questions
      await handleSaveAndContinue();
    } catch (error) {
      logger.error('QuizComponent', 'Failed to handle continue', error);
    }
  }, [quizProgress, showResult, dispatch, handleSaveAndContinue]);

  if (quizProgress?.completed) {
    logger.info('QuizComponent', 'Quiz is completed, showing summary', {
      completed: quizProgress.completed,
      currentIndex: quizProgress.currentQuestionIndex,
      totalQuestions: quizProgress.questions.length
    });
    
    return (
      <QuizSummary
        quizProgress={quizProgress}
        timeSpent={timeSpent}
        onRestartQuiz={() => navigate('/dashboard')}
        showFeedbackButton={true}
      />
    );
  }

  // Handle quiz completion or invalid state
  if (!currentQuestion) {
    // If we get here, there's an actual error
    logger.error('[QuizComponent] Invalid quiz state', String(quizProgress.currentQuestionIndex));
    
    return (
      <div className="text-center py-8 text-red-500">
        Error loading quiz content. Please try again.
      </div>
    );
  }

  return (
    <div className={`${isSubscriber ? 'subscriber-quiz' : 'free-quiz'} space-y-6`}>
      {/* Adaptive Learning UI */}
      {adaptiveState && userData?.profile?.onboardingCompleted && (
        <div className="text-sm text-gray-600 dark:text-gray-400 flex items-center justify-between">
          <div>
            Difficulty: {adaptiveState.currentDifficulty}/10
            {adaptiveState.insights && adaptiveState.insights.performanceTrend && adaptiveState.insights.performanceTrend !== 'steady' && (
              <span className="ml-2">
                ({adaptiveState.insights.performanceTrend})
              </span>
            )}
          </div>
          {adaptiveState.insights?.weakCategories?.length > 0 && (
            <div className="text-yellow-600 dark:text-yellow-400">
              Focus areas: {adaptiveState.insights.weakCategories
                .reduce((lowest, current) => 
                  current.successRate < lowest.successRate ? current : lowest
                ).category}
            </div>
          )}
        </div>
      )}
      
      <QuestionDisplay
        question={currentQuestion}
        showHint={showHint}
        setShowHint={setShowHint}
      />
      
      <AnswerOptions
        currentQuestion={currentQuestion}
        selectedAnswer={selectedAnswer}
        showResult={showResult}
        handleAnswer={handleAnswerClick}
        isCorrect={(option) => option === currentQuestion.correct_answer}
      />

      {showResult && selectedAnswer && (
        <ResultDisplay
          question={currentQuestion}
          selectedAnswer={selectedAnswer}
          currentExplanation={currentExplanation}
          handleExplanationChange={handleExplanationChange}
          handleSaveAndContinue={handleContinue}
          showContinueButton={showContinueButton}
          continueButtonText={continueButtonText}
          // handleContinue={handleContinue}
        />
      )}
    </div>
  );
};

QuizComponentBase.displayName = 'QuizComponent';

// Add a render logging wrapper
const withRenderLogging = (WrappedComponent: React.ComponentType<any>, componentName: string) => {
  const WithLogging = React.memo((props: any) => {
    console.log(`[Render] ${componentName}`, {
      props: { ...props, children: undefined }, // Exclude children from logging
      timestamp: new Date().toISOString()
    });
    return <WrappedComponent {...props} />;
  });

  // Set display name for the wrapped component
  WithLogging.displayName = `WithLogging(${componentName})`;
  return WithLogging;
};

// Add display name to base component
QuizComponentBase.displayName = 'QuizComponent';

// Export with memo and logging
const MemoizedQuizComponent = React.memo(QuizComponentBase, (prevProps, nextProps) => {
  const areEqual = (
    prevProps.selectedAnswer === nextProps.selectedAnswer &&
    prevProps.showResult === nextProps.showResult &&
    prevProps.showHint === nextProps.showHint &&
    prevProps.currentExplanation === nextProps.currentExplanation
  );

  console.log('[Memo Comparison]', {
    areEqual,
    changedProps: Object.keys(prevProps).filter(key => 
      prevProps[key as keyof typeof prevProps] !== nextProps[key as keyof typeof nextProps]
    )
  });

  return areEqual;
});

MemoizedQuizComponent.displayName = 'MemoizedQuizComponent';

export default withRenderLogging(MemoizedQuizComponent, 'QuizComponent');

