import { useAuth } from '../contexts/AuthContext';
import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
// import { format } from 'date-fns';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { updateUserProfile } from '../utils/firebase/user';
import { removeWrongAnswer, updateWrongAnswerExplanation } from '../utils/firebase/testResults';
import TestScores from './dashboard/TestScores';
import FlashCards from './dashboard/FlashCards';
import QuizActions from './dashboard/QuizActions';
// import { TestResult, WrongAnswer } from '../models/UserTypes';
import OnboardingModal from './OnboardingModal';
import { buildShareText, shareToSocial } from '../utils/shareUtils';
import { Calendar as CalendarIcon, Share2 } from 'lucide-react';
import AdaptiveInsightsCard from './dashboard/AdaptiveInsightsCard';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../utils/Analytics';
import { useQuiz } from '../hooks/useQuiz';
import { logger } from '../utils/logger';
import StudyOptionsComponent from './dashboard/StudyOptionsComponent';
import { calculateDaysUntilExam, formatExamDate } from './dashboard/utils';
import QuizErrorBoundary from './QuizErrorBoundary';

const Dashboard: React.FC = () => {
    const { currentUser, userData, isLoading } = useAuth();
    const navigate = useNavigate();
    const renderCount = useRef(0);
    const { initializeQuizWithParams } = useQuiz();

    // Memoize state initialization
    const [state, setState] = useState(() => ({
        isLoading: !userData,
        wrongAnswers: userData?.totalWrongAnswers ?? [],
        showOnboarding: userData?.profile?.onboardingCompleted === false,
        latestResult: userData?.latestTestResult ?? null,
        showDatePicker: false
    }));

    // Use useLayoutEffect for initial setup
    useLayoutEffect(() => {
        if (!isLoading && !currentUser) {
            logger.info('Dashboard', 'No user found, redirecting to signin');
            navigate('/signin', { 
                replace: true,
                state: { from: '/dashboard' } 
            });
            return;
        }
    }, [currentUser, isLoading, navigate]);

    // Debug render count - now with isLoading info
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            renderCount.current++;
            logger.debug('Dashboard', 'Component rendered', {
                renderCount: renderCount.current,
                hasUser: !!currentUser,
                hasUserData: !!userData,
                isLoading
            });
        }
    });

    // Update state helper
    const updateState = useCallback((updates: Partial<typeof state>) => {
        setState(prev => ({ ...prev, ...updates }));
    }, []);

    const handleUpdateExplanation = useCallback(async (questionId: number, explanation: string) => {
        if (!currentUser) return;
        try {
            await updateWrongAnswerExplanation(currentUser.uid, questionId, explanation);
            logAnalyticsEvent(ANALYTICS_EVENTS.FLASHCARD_EXPLANATION_UPDATED, {
                questionId,
                explanationLength: explanation.length,
                isSubscribed: userData?.isSubscribed
            });
            // await refreshUserData();
        } catch (error) {
            logger.error('Dashboard', 'Error updating explanation', { error });
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'update_flashcard_explanation',
                error: error
            });
        }
    }, [currentUser, userData]);

    const handleDateSelect = useCallback(async (date: Date) => {
        if (!currentUser) return;
        try {
            const updatedProfile = {
                ...(userData?.profile || {}),
                examDate: date.toISOString()
            };

            await updateUserProfile(currentUser.uid, updatedProfile);
            logAnalyticsEvent(ANALYTICS_EVENTS.EXAM_DATE_UPDATED, {
                oldDate: userData?.profile?.examDate,
                newDate: date.toISOString(),
                daysUntilExam: calculateDaysUntilExam(date.toISOString()),
                isSubscribed: userData?.isSubscribed
            });
            
            updateState({ showDatePicker: false });
        } catch (error) {
            logger.error('Dashboard', 'Error updating exam date', { error });
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'update_exam_date',
                error: error
            });
        }
    }, [currentUser, userData, updateState]);

    const handleMarkComplete = useCallback(async (questionId: number) => {
        if (!currentUser) return;
        try {
            // Update in Firebase
            await removeWrongAnswer(currentUser.uid, questionId);
            
            // Log analytics
            logAnalyticsEvent(ANALYTICS_EVENTS.FLASHCARD_MARKED_COMPLETE, {
                questionId,
                isSubscribed: userData?.isSubscribed
            });
            
            // Refresh user data to get updated wrong answers
            // await refreshUserData();
            
            logger.success('Dashboard', 'Question marked as complete', { questionId });
        } catch (error) {
            logger.error('Dashboard', 'Error marking question complete', { error });
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'mark_flashcard_complete',
                error: error
            });
        }
    }, [currentUser, userData]);

    const handleShare = useCallback(async (platform: 'x' | 'facebook' | 'linkedin', text: string) => {
        try {
            await shareToSocial(platform, text);
            
            logAnalyticsEvent(ANALYTICS_EVENTS.SHARE_RESULT, {
                platform,
                hasResult: !!state.latestResult,
                isSubscribed: userData?.isSubscribed
            });
            
            logger.success('Dashboard', 'Shared to social media', { 
                platform, 
                textLength: text.length 
            });
        } catch (error) {
            logger.error('Dashboard', 'Error sharing to social', { 
                error, 
                platform 
            });
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'share_result',
                platform,
                error: error
            });
        }
    }, [state.latestResult, userData?.isSubscribed]);

    // 5. Render
    if (isLoading || !userData) {
        return <LoadingSpinner />;
    }

    return (
        <div className="new-home-container">
            <div className="new-home-content">
                <header className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
                        Hi, {userData?.profile?.name || 'Future ASWB Graduate'}. Welcome Back to your ASWB Test Prep!
                    </h1>
                </header>
                <div className="new-home-inner">
                    {/* Top Row - Fixed Height Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                        {/* Exam Countdown - Add max-height */}
                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600 h-[200px]">
                            <div className="flex justify-between items-start mb-4">
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Exam Countdown</h2>
                                {userData?.profile?.examDate && (
                                    <button
                                        onClick={() => updateState({ showDatePicker: true })}
                                        className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                                    >
                                        Change Date
                                    </button>
                                )}
                            </div>
                            {userData?.profile?.examDate && formatExamDate(userData.profile.examDate) ? (
                                <>
                                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                                        {calculateDaysUntilExam(userData.profile.examDate)} days
                                    </p>
                                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                        Exam date: {formatExamDate(userData.profile.examDate)}
                                    </p>
                                </>
                            ) : (
                                <div className="text-center py-4">
                                    <p className="text-3xl font-bold text-gray-500 dark:text-gray-400 mb-4">Not scheduled</p>
                                    <button
                                        onClick={() => updateState({ showDatePicker: true })}
                                        className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                    >
                                        <CalendarIcon className="w-4 h-4 mr-2" />
                                        Set Exam Date
                                    </button>
                                </div>
                            )}
                        </div>
                        
                        {/* Latest Score - Add max-height */}
                        {userData?.latestTestResult && (
                            <div className="h-[200px]">
                                <TestScores testResults={userData.latestTestResult} />
                            </div>
                        )}

                        {/* Quiz Actions - Add max-height */}
                        {userData?.quizzes && Object.keys(userData.quizzes).length > 0 && (
                            <div className="h-[200px]">
                                <QuizActions isSubscribed={userData.isSubscribed} />
                            </div>
                        )}
                    </div>

                    {/* Study Options Section */}
                    <div className="study-options-section mb-6">
                        <QuizErrorBoundary>
                            <StudyOptionsComponent 
                                userData={userData}
                                initializeQuizWithParams={initializeQuizWithParams}
                                key={`study-options-${userData?.id}`}
                            />
                        </QuizErrorBoundary>
                    </div>

                    {/* Adaptive Insights - Full Width */}
                    <div className="mb-6">
                        <AdaptiveInsightsCard adaptiveLearning={userData?.adaptiveLearning} />
                    </div>

                    {/* Flashcards Section - Full Width */}
                    <div className="mb-6">
                        <FlashCards 
                            wrongAnswers={state.wrongAnswers}
                            onUpdateExplanation={handleUpdateExplanation}
                            onMarkComplete={handleMarkComplete}
                        />
                    </div>

                    {/* Bottom Row - Social Sharing */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600">
                            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200 flex items-center">
                                <Share2 className="mr-2" /> Share Your Progress
                            </h2>
                            <p className="text-gray-600 dark:text-gray-400 mb-4">
                                {state.latestResult 
                                    ? `Share your ${state.latestResult.score}/${state.latestResult.totalQuestions} score!`
                                    : 'Take a quiz to share your results!'}
                            </p>
                            <div className="flex space-x-2">
                                <button 
                                    onClick={() => handleShare('x', buildShareText(state.latestResult))}
                                    className="bg-black hover:bg-gray-900 text-white p-2 rounded-full transition-colors"
                                    disabled={!state.latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => handleShare('facebook', buildShareText(state.latestResult))}
                                    className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-full transition-colors"
                                    disabled={!state.latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => handleShare('linkedin', buildShareText(state.latestResult))}
                                    className="bg-blue-700 hover:bg-blue-800 text-white p-2 rounded-full transition-colors"
                                    disabled={!state.latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {state.showOnboarding && (
                <OnboardingModal 
                    onClose={() => updateState({ showOnboarding: false })} 
                />
            )}

            {state.showDatePicker && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
                            Select Exam Date
                        </h3>
                        <input
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => handleDateSelect(new Date(e.target.value))}
                            className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        />
                        <button
                            onClick={() => updateState({ showDatePicker: false })}
                            className="mt-4 w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded-lg"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

// const ReviewConfirmationModal = ({ 
//     onConfirm, 
//     onClose 
// }: { 
//     onConfirm: () => void;
//     onClose: () => void;
// }) => {
//     return (
//         <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
//             <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
//                 <div className="flex justify-between items-start mb-4">
//                     <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
//                         Mark as Reviewed
//                     </h3>
//                     <button 
//                         onClick={onClose} 
//                         className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
//                     >
//                         <span className="sr-only">Close</span>
//                         <XIcon className="h-6 w-6" />
//                     </button>
//                 </div>
                
//                 <p className="text-gray-600 dark:text-gray-400 mb-6">
//                     Are you confident that you understand this concept and don&apos;t need to review it anymore?
//                 </p>

//                 <div className="flex space-x-3">
//                     <button
//                         onClick={onConfirm}
//                         className="flex-1 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 
//                                  dark:bg-blue-500 dark:hover:bg-blue-600 text-white"
//                     >
//                         Yes, I understand it
//                     </button>
//                     <button
//                         onClick={onClose}
//                         className="flex-1 border border-gray-300 dark:border-gray-600 
//                                  px-4 py-2 rounded-lg text-gray-700 dark:text-gray-300
//                                  hover:bg-gray-50 dark:hover:bg-gray-700"
//                     >
//                         Cancel
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

export default Dashboard;
