import { db, auth } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteField, 
  limit, 
  QueryConstraint,
  addDoc,
  runTransaction,
  FirestoreError
} from 'firebase/firestore';
import { 
  Question,
  QuizProgress, 
  QuizType,
  QuizFetchParams 
} from '../quiz/types';
import { sanitizeQuestion } from '../quiz/validation';
import { migrateQuizData, determineQuizType } from './migration';
import typedQuestionsData from '../questions.json';
import { QuizError, QuizErrorCodes } from '../quiz/errors';
import { quizCache, questionCache } from './cache';
import { CACHE_CONFIG } from './cache';
import { logger } from '../logger';

interface CacheKeyGenerators {
  questions: (params: QuizFetchParams) => string;
  quiz: (userId: string, quizId: number) => string;
  activeQuizzes: (userId: string) => string;
}

const generateCacheKey: CacheKeyGenerators = {
  questions: (params: QuizFetchParams): string => 
    `questions:${params.type}:${params.category ?? 'all'}:${params.questionIds?.join(',') ?? 'none'}`,
    
  quiz: (userId: string, quizId: number): string => 
    `quiz:${userId}:${quizId}`,
    
  activeQuizzes: (userId: string): string => 
    `activeQuizzes:${userId}`
};

interface QuizData {
  quizzes?: Record<string, Partial<QuizProgress>>;
  quizProgress?: any; // Define proper type if known
}

interface QuizDelta {
  type: 'ANSWER' | 'START' | 'COMPLETE' | 'METADATA';
  timestamp: number;
  quizId: number;
  userId: string;
  changes: {
    path: string;
    value: any;
  }[];
  processed: boolean;
  quizRef?: string;
}

// Constants for Firestore limits
const FIRESTORE_LIMITS = {
  MAX_STRING_LENGTH: 1048487, // Firestore's max string length
  MAX_ARRAY_LENGTH: 20000,    // Firestore's max array elements
  MAX_DOC_SIZE: 1048576       // 1MB in bytes
};

// Add before validateQuizId
const estimateDocumentSize = (obj: any): number => {
  const stringify = (val: any): string => {
    if (val === null || val === undefined) return '';
    if (typeof val === 'string') return val;
    return JSON.stringify(val);
  };

  let size = 0;
  const processObject = (obj: any) => {
    for (const key in obj) {
      // Add key size
      size += key.length;
      
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object' && item !== null) {
            processObject(item);
          } else {
            size += stringify(item).length;
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        processObject(value);
      } else {
        size += stringify(value).length;
      }
    }
  };

  processObject(obj);
  return size;
};

// Add before validateQuizId
const validateQuizId = (id: any): boolean => {
  const numId = Number(id);
  return (
    !isNaN(numId) && 
    Number.isFinite(numId) && 
    Number.isInteger(numId) && 
    numId > 0 && 
    numId <= Number.MAX_SAFE_INTEGER
  );
};

const sanitizeQuizId = (id: any): number => {
  if (!validateQuizId(id)) {
    logger.error('Quiz', 'Invalid quiz ID', { id });
    throw new QuizError('Invalid quiz ID', QuizErrorCodes.INVALID_DATA, false);
  }
  return Number(id);
};

// Add after FIRESTORE_LIMITS
const detectCircularReferences = (obj: any, seen = new WeakSet()): string[] => {
  const paths: string[] = [];
  
  const detect = (obj: any, path: string[] = []) => {
    if (obj && typeof obj === 'object') {
      if (seen.has(obj)) {
        paths.push(path.join('.'));
        return;
      }
      seen.add(obj);
      
      for (const [key, value] of Object.entries(obj)) {
        detect(value, [...path, key]);
      }
    }
  };
  
  detect(obj);
  return paths;
};

const validateQuizType = (type: any): type is QuizType => {
  return Object.values(QuizType).includes(type);
};

const validateAdaptiveState = (state: any): boolean => {
  if (!state) return true; // null is valid
  
  if (typeof state !== 'object') {
    logger.error('Quiz', 'Invalid adaptive state type', { type: typeof state });
    return false;
  }

  // Validate categoryStrengths
  if (state.categoryStrengths && typeof state.categoryStrengths === 'object') {
    const strengths = state.categoryStrengths as Record<string, {
      correctCount?: number | string;
      totalCount?: number | string;
      averageTime?: number | string;
    }>;

    for (const [category, strength] of Object.entries(strengths)) {
      if (!strength || typeof strength !== 'object') {
        logger.error('Quiz', 'Invalid category strength', { category, strength });
        return false;
      }

      const requiredFields = ['correctCount', 'totalCount', 'averageTime'] as const;
      for (const field of requiredFields) {
        const value = Number(strength[field]);
        if (isNaN(value) || !isFinite(value)) {
          logger.error('Quiz', `Invalid ${field} in category strength`, { 
            category, 
            field, 
            value: strength[field] 
          });
          return false;
        }
      }
    }
  }

  // Validate insights
  if (state.insights) {
    const { recommendedDifficulty } = state.insights;
    if (typeof recommendedDifficulty !== 'undefined') {
      const difficulty = Number(recommendedDifficulty);
      if (isNaN(difficulty) || difficulty < 1 || difficulty > 10) {
        logger.error('Quiz', 'Invalid recommended difficulty', { difficulty });
        return false;
      }
    }
  }

  return true;
};

const validateQuizData = (data: any): boolean => {
  // Check for circular references
  const circularPaths = detectCircularReferences(data);
  if (circularPaths.length > 0) {
    logger.error('Quiz', 'Circular references detected', { paths: circularPaths });
    return false;
  }

  // QuizId validation
  if (!validateQuizId(data.quizId)) {
    logger.error('Quiz', 'Invalid quiz ID in data', { quizId: data.quizId });
    return false;
  }

  // Quiz type validation
  if (!validateQuizType(data.type)) {
    logger.error('Quiz', 'Invalid quiz type', { type: data.type });
    return false;
  }

  // Required fields with type validation
  const requiredNumberFields = ['currentQuestionIndex', 'score'];
  for (const field of requiredNumberFields) {
    if (typeof data[field] !== 'number' || isNaN(data[field])) {
      logger.error('Quiz', `Invalid ${field}`, { value: data[field] });
      return false;
    }
  }

  // Category validation
  if (typeof data.category !== 'string') {
    logger.error('Quiz', 'Invalid category type', { category: data.category });
    return false;
  }

  // Adaptive state validation
  if (!validateAdaptiveState(data.adaptiveState)) {
    return false;
  }

  // Questions array validation
  if (!data.questions || !Array.isArray(data.questions)) {
    logger.error('Quiz', 'Missing or invalid questions array');
    return false;
  }
  
  if (data.questions.length === 0) {
    logger.error('Quiz', 'Empty questions array');
    return false;
  }
  
  // Size validations
  if (data.questions.length > FIRESTORE_LIMITS.MAX_ARRAY_LENGTH) {
    logger.error('Quiz', 'Questions array exceeds maximum length', {
      length: data.questions.length,
      max: FIRESTORE_LIMITS.MAX_ARRAY_LENGTH
    });
    return false;
  }

  // Wrong answers validation
  if (data.wrongAnswers) {
    if (!Array.isArray(data.wrongAnswers)) {
      logger.error('Quiz', 'Invalid wrongAnswers type', { type: typeof data.wrongAnswers });
      return false;
    }
    
    if (data.wrongAnswers.length > FIRESTORE_LIMITS.MAX_ARRAY_LENGTH) {
      logger.error('Quiz', 'WrongAnswers array exceeds maximum length', {
        length: data.wrongAnswers.length,
        max: FIRESTORE_LIMITS.MAX_ARRAY_LENGTH
      });
      return false;
    }
  }

  // Validate each question
  return data.questions.every((q: any, index: number) => {
    if (!validateQuizId(q.id)) {
      logger.error('Quiz', 'Invalid question ID', { 
        questionIndex: index, 
        id: q.id 
      });
      return false;
    }

    const requiredFields = ['question_text', 'correct_answer'];
    for (const field of requiredFields) {
      if (!q[field] || typeof q[field] !== 'string') {
        logger.error('Quiz', `Missing or invalid ${field}`, { 
          questionIndex: index,
          field,
          value: q[field]
        });
        return false;
      }
    }

    // Check string lengths
    const stringFields = [
      'question_text', 'option_a', 'option_b', 'option_c', 
      'option_d', 'explanation', 'correct_answer'
    ];
    
    return stringFields.every(field => {
      if (q[field] && typeof q[field] === 'string' && 
          q[field].length > FIRESTORE_LIMITS.MAX_STRING_LENGTH) {
        logger.error('Quiz', `Field ${field} exceeds maximum length`, { 
          questionIndex: index,
          field,
          length: q[field].length,
          max: FIRESTORE_LIMITS.MAX_STRING_LENGTH
        });
        return false;
      }
      return true;
    });
  });
};

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY_MS = 1000;

interface TransactionMetrics {
  stageTiming: Record<string, number>;
  lastStageTime: number;
  validationTime?: number;
  serializationTime?: number;
  documentSize: number;
  memoryUsage: {
    heapUsed: number;
    heapTotal: number;
  };
}

interface TransactionContext {
  attempt: number;
  startTime: number;
  stage: 'init' | 'validation' | 'serialization' | 'user_check' | 'quiz_save' | 'user_update' | 'complete';
  metrics: TransactionMetrics;
}

const measurePerformance = () => {
  const memoryUsage = process?.memoryUsage?.() || { heapUsed: 0, heapTotal: 0 };
  return {
    heapUsed: Math.round(memoryUsage.heapUsed / 1024 / 1024), // MB
    heapTotal: Math.round(memoryUsage.heapTotal / 1024 / 1024) // MB
  };
};

const updateMetrics = (context: TransactionContext, newStage: TransactionContext['stage']) => {
  const now = Date.now();
  if (context.metrics.lastStageTime) {
    context.metrics.stageTiming[context.stage] = now - context.metrics.lastStageTime;
  }
  context.stage = newStage;
  context.metrics.lastStageTime = now;
};

// Add after TransactionContext interface
interface ErrorClassification {
  type: 'FIRESTORE' | 'VALIDATION' | 'UNKNOWN';
  code?: string;
  retryable: boolean;
}

const classifyError = (error: unknown): ErrorClassification => {
  if (error instanceof FirestoreError) {
    return {
      type: 'FIRESTORE',
      code: error.code,
      retryable: ['deadline-exceeded', 'unavailable', 'resource-exhausted'].includes(error.code)
    };
  }
  if (error instanceof QuizError) {
    return {
      type: 'VALIDATION',
      code: error.code,
      retryable: error.recoverable
    };
  }
  return {
    type: 'UNKNOWN',
    retryable: false
  };
};

const saveFullQuizState = async (progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  let context: TransactionContext = {
    attempt: 0,
    startTime: Date.now(),
    stage: 'init',
    metrics: {
      stageTiming: {},
      lastStageTime: Date.now(),
      documentSize: 0,
      memoryUsage: measurePerformance()
    }
  };

  let lastError: Error | null = null;

  try {
    // Validation phase
    updateMetrics(context, 'validation');
    let sanitizedProgress = migrateQuizProgress(progress);
    const quizId = sanitizeQuizId(sanitizedProgress.quizId);

    if (!validateQuizData(sanitizedProgress)) {
      const validationError = new QuizError('Invalid quiz data structure', QuizErrorCodes.INVALID_DATA, false);
      const errorClass = classifyError(validationError);
      logger.error('Quiz', 'Validation failed', {
        error: validationError,
        errorType: errorClass.type,
        duration: Date.now() - context.metrics.lastStageTime,
        memoryUsage: measurePerformance()
      });
      throw validationError;
    }
    context.metrics.validationTime = Date.now() - context.metrics.lastStageTime;

    // Serialization phase
    updateMetrics(context, 'serialization');
    const { docData, adaptiveState } = await (async () => {
      // Remove engine instance and convert dates to ISO strings
      const adaptiveState = sanitizedProgress.adaptiveState ? {
        ...sanitizedProgress.adaptiveState,
        engine: null,
        categoryStrengths: Object.fromEntries(
          Object.entries(sanitizedProgress.adaptiveState.categoryStrengths || {}).map(([key, value]) => [
            key,
            {
              ...value,
              lastAttempted: value.lastAttempted instanceof Date 
                ? value.lastAttempted.toISOString() 
                : value.lastAttempted,
              correctCount: Number(value.correctCount) || 0,
              totalCount: Number(value.totalCount) || 0,
              averageTime: Number(value.averageTime) || 0
            }
          ])
        ),
        insights: sanitizedProgress.adaptiveState.insights ? {
          ...sanitizedProgress.adaptiveState.insights,
          recommendedDifficulty: Number(sanitizedProgress.adaptiveState.insights.recommendedDifficulty) || 5
        } : null
      } : null;

      const questionsData = sanitizedProgress.questions.map(q => ({
        id: String(q.id).slice(0, 512), // Enforce Firestore doc ID length limit
        option_a: String(q.option_a || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        option_b: String(q.option_b || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        option_c: String(q.option_c || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        option_d: String(q.option_d || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        option_e: q.option_e ? String(q.option_e).slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH) : null,
        correct_answer: String(q.correct_answer || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        question_text: String(q.question_text || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        category: String(q.category || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        explanation: String(q.explanation || '').slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH),
        difficulty_level: q.difficulty_level ? Number(q.difficulty_level) : null,
        selected_answer: q.selected_answer ? String(q.selected_answer).slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH) : null,
        hint: q.hint ? String(q.hint).slice(0, FIRESTORE_LIMITS.MAX_STRING_LENGTH) : null
      }));

      const docData = {
        quizId,
        currentQuestionIndex: sanitizedProgress.currentQuestionIndex,
        score: sanitizedProgress.score,
        type: sanitizedProgress.type,
        category: sanitizedProgress.category,
        questions: questionsData,
        wrongAnswers: sanitizedProgress.wrongAnswers || [],
        adaptiveState,
        userId: user.uid,
        lastUpdated: new Date().toISOString(),
        date: sanitizedProgress.date || new Date().toISOString()
      };

      return { docData, adaptiveState };
    })();
    context.metrics.serializationTime = Date.now() - context.metrics.lastStageTime;

    // Size check and warnings
    const estimatedSize = estimateDocumentSize(docData);
    context.metrics.documentSize = estimatedSize;
    
    if (estimatedSize > 512 * 1024) { // 512KB warning threshold
      logger.warn('Quiz', 'Large document warning', {
        size: estimatedSize,
        threshold: 512 * 1024,
        suggestion: 'Consider implementing delta compression for questions array',
        metrics: {
          questionCount: docData.questions.length,
          averageQuestionSize: Math.round(estimatedSize / docData.questions.length)
        }
      });
    }
    
    if (estimatedSize > FIRESTORE_LIMITS.MAX_DOC_SIZE) {
      const sizeError = new QuizError('Document size too large', QuizErrorCodes.DOC_SIZE_EXCEEDED, false);
      const errorClass = classifyError(sizeError);
      logger.error('Quiz', 'Document size exceeds Firestore limit', {
        size: estimatedSize,
        limit: FIRESTORE_LIMITS.MAX_DOC_SIZE,
        errorType: errorClass.type,
        metrics: context.metrics
      });
      throw sizeError;
    }

    while (context.attempt < MAX_RETRY_ATTEMPTS) {
      try {
        await runTransaction(db, async (transaction) => {
          // User check phase
          updateMetrics(context, 'user_check');
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await transaction.get(userRef);
          
          if (!userDoc.exists()) {
            throw new QuizError('User document not found', QuizErrorCodes.USER_NOT_FOUND, false);
          }

          // Quiz save phase
          updateMetrics(context, 'quiz_save');
          const quizRef = doc(db, 'quizzes', `${user.uid}_${quizId}`);
          transaction.set(quizRef, docData);

          // User update phase
          updateMetrics(context, 'user_update');
          transaction.update(userRef, {
            [`quizzes.${quizId}`]: {
              id: quizId,
              lastUpdated: new Date().toISOString(),
              ref: quizRef.path
            }
          });

          updateMetrics(context, 'complete');
        });

        // Success logging with size metrics
        logger.info('Quiz', 'Saved quiz state successfully', {
          quizId,
          userId: user.uid,
          metrics: {
            ...context.metrics,
            totalDuration: Date.now() - context.startTime,
            attempts: context.attempt + 1,
            finalMemoryUsage: measurePerformance(),
            stageTiming: context.metrics.stageTiming,
            documentSizeRatio: Math.round((estimatedSize / FIRESTORE_LIMITS.MAX_DOC_SIZE) * 100) + '%'
          }
        });
        return;

      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error occurred');
        context.attempt++;
        const currentMemory = measurePerformance();
        const errorClass = classifyError(error);

        logger.warn('Quiz', 'Transaction attempt failed', {
          attempt: context.attempt,
          stage: context.stage,
          error: error instanceof Error ? error.message : 'Unknown error',
          errorClassification: errorClass,
          metrics: {
            stageTiming: context.metrics.stageTiming,
            currentDuration: Date.now() - context.startTime,
            memoryDelta: {
              heapUsed: currentMemory.heapUsed - context.metrics.memoryUsage.heapUsed,
              heapTotal: currentMemory.heapTotal - context.metrics.memoryUsage.heapTotal
            }
          }
        });

        if (!errorClass.retryable) break;

        if (context.attempt < MAX_RETRY_ATTEMPTS) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS * context.attempt));
        }
      }
    }

    // All attempts failed - log comprehensive metrics with error classification
    const finalMemory = measurePerformance();
    const finalErrorClass = classifyError(lastError);
    
    logger.error('Quiz', 'Failed to save quiz state after all attempts', {
      error: lastError,
      errorClassification: finalErrorClass,
      metrics: {
        attempts: context.attempt,
        lastStage: context.stage,
        documentSize: context.metrics.documentSize,
        documentSizeRatio: Math.round((estimatedSize / FIRESTORE_LIMITS.MAX_DOC_SIZE) * 100) + '%',
        totalDuration: Date.now() - context.startTime,
        stageTiming: context.metrics.stageTiming,
        memoryProfile: {
          initial: context.metrics.memoryUsage,
          final: finalMemory,
          delta: {
            heapUsed: finalMemory.heapUsed - context.metrics.memoryUsage.heapUsed,
            heapTotal: finalMemory.heapTotal - context.metrics.memoryUsage.heapTotal
          }
        }
      }
    });

    throw lastError || new QuizError('Failed to save quiz state', QuizErrorCodes.SAVE_FAILED, false);
  } catch (error) {
    // Catch any errors in the validation/serialization phase
    const errorClass = classifyError(error);
    logger.error('Quiz', 'Critical error in quiz save process', {
      error,
      errorClassification: errorClass,
      stage: context.stage,
      metrics: {
        duration: Date.now() - context.startTime,
        memoryUsage: measurePerformance()
      }
    });
    throw error;
  }
};

// Core quiz fetching function
export const fetchQuestions = async (params: QuizFetchParams): Promise<Question[]> => {
  const cacheKey = generateCacheKey.questions(params);
  
  try {
    // Try cache first
    const cachedQuestions = await questionCache.get<Question[]>(cacheKey);
    if (cachedQuestions) {
      logger.debug(`Cached questions:`, `${cachedQuestions.length}`);
      return cachedQuestions;
    }

    // Fetch fresh data
    let questions: Question[];

    if (params.type === QuizType.SAMPLE) {
      questions = shuffleArray(typedQuestionsData.map(sanitizeQuestion));
    } else {
      // Determine the collection path based on testName
      let questionsRef;
      if (params.testName) {
        // Get questions from specific test collection
        const testDocRef = doc(db, 'tests', params.testName);
        questionsRef = collection(testDocRef, 'questions');
      } else {
        // Fallback to main questions collection
        questionsRef = collection(db, 'questions');
      }

      const constraints = buildQueryConstraints(params);
      const querySnapshot = await getDocs(query(questionsRef, ...constraints));
      
      if (querySnapshot.empty) {
        return [];
      }

      questions = querySnapshot.docs.map(doc => 
        sanitizeQuestion({
          ...doc.data() as Question,
          id: doc.id
        })
      );
    }

    // Cache the results
    await questionCache.set(cacheKey, questions, CACHE_CONFIG.questions.ttl);
    return questions;
  } catch (error) {
    console.error('Error in fetchQuestions:', error);
    throw error;
  }
};

// Quiz progress management
export const saveQuizProgress = async (progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  let sanitizedProgress = migrateQuizProgress(progress);

  const questionsData = sanitizedProgress.questions.map(q => ({
    id: q.id,
    question: q,
    option_a: q.option_a,
    option_b: q.option_b,
    option_c: q.option_c,
    option_d: q.option_d,
    option_e: q.option_e || null,
    correct_answer: q.correct_answer,
    question_text: q.question_text,
    category: q.category,
    explanation: q.explanation,
  }))

  // Create a clean, serializable version of the quiz progress
  const cleanProgress = {
    quizId: sanitizedProgress.quizId,
    type: sanitizedProgress.type,
    category: sanitizedProgress.category || null,
    currentQuestionIndex: sanitizedProgress.currentQuestionIndex || 0,
    questions: questionsData,
    score: sanitizedProgress.score || 0,
    questionIds: sanitizedProgress.questionIds || sanitizedProgress.questions.map(q => q.id),
    wrongAnswers: sanitizedProgress.wrongAnswers || [],
    date: sanitizedProgress.date || new Date().toISOString(),
    lastUpdated: sanitizedProgress.lastUpdated || new Date().toISOString(),
    adaptiveState: {
      ...sanitizedProgress.adaptiveState,
      engine: null,
      currentDifficulty: sanitizedProgress.adaptiveState.currentDifficulty || 5,
      categoryStrengths: sanitizedProgress.adaptiveState.categoryStrengths || {}
    }
  };

  const dataSize = JSON.stringify(cleanProgress).length;
  const questionsSize = JSON.stringify(questionsData).length;

  logger.debug('Quiz Save', 'Size Check', {
    mainDataSize: dataSize,
    questionsDataSize: questionsSize,
    totalSize: dataSize + questionsSize,
    questionCount: questionsData.length
  });

  const MAX_RETRIES = 3;
  let retryCount = 0;
  while (retryCount < MAX_RETRIES) {
    try {
      await runTransaction(db, async (transaction) => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await transaction.get(userDocRef);
        
        if (!userDoc.exists()) {
          throw new QuizError('User document not found', QuizErrorCodes.USER_NOT_FOUND, false);
        }

        // Log the exact update operation with correct arguments
        logger.debug(`Quiz Save - Update Operation: users/${user.uid}/quizzes.${progress.quizId}`, `dataSize: ${JSON.stringify(cleanProgress).length}`);

        transaction.update(userDocRef, {
          [`quizzes.${progress.quizId}`]: cleanProgress
        });

        // Store questions in a separate subcollection
        // const quizQuestionsRef = collection(db, 'users', user.uid, 'quizQuestions');
        // const questionsDoc = doc(quizQuestionsRef, `quiz_${progress.quizId}`);
        
        // transaction.set(questionsDoc, {
        //   questions: questionsData,
        //   lastUpdated: new Date().toISOString()
        // });
      });

      logger.debug('Quiz', 'Transaction completed successfully', {
        quizId: progress.quizId,
        userId: user.uid
      });

      break;
    } catch (error: any) {
      retryCount++;
      
      logger.error('Quiz Save - Transaction Failed', `error: ${error}, retryCount: ${retryCount}, errorType: ${error.constructor.name}, errorCode: ${error instanceof FirestoreError ? error.code : 'unknown'}, errorMessage: ${error instanceof Error ? error.message : 'Unknown error'}, stack: ${error instanceof Error ? error.stack : undefined}`);

      if (error instanceof FirestoreError && 
          ['deadline-exceeded', 'unavailable', 'failed-precondition'].includes(error.code) && 
          retryCount < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        continue;
      }
      
      throw new QuizError(
        'Failed to save quiz progress',
        QuizErrorCodes.SAVE_FAILED,
        true
      );
    }
  }
};

// Helper functions
const buildQueryConstraints = ({
  type,
  category,
  questionIds,
  isSubscribed
}: QuizFetchParams): QueryConstraint[] => {
  const constraints: QueryConstraint[] = [];

  switch (type) {
    case QuizType.CATEGORY:
      if (!category) throw new Error('Category required for category quiz');
      constraints.push(
        where('category', '==', category),
        limit(50)
      );
      break;
    case QuizType.FULL:
      if (!isSubscribed) throw new Error('Full quiz requires subscription');
      constraints.push(limit(170));
      break;
    default:
      constraints.push(limit(15));
  }

  if (questionIds?.length) {
    constraints.push(where('id', 'in', questionIds));
  }

  return constraints;
};

export const getActiveQuiz = async (
  quizId: number
): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  // const cacheKey = generateCacheKey.quiz(user.uid, quizId);
  
  try {
    // Try cache first
    // const cachedQuiz = await quizCache.get<QuizProgress>(cacheKey);
    // if (cachedQuiz) {
    //   return cachedQuiz;
    // }

    // Fetch fresh data
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    let quiz: QuizProgress | null = null;

    if (data?.quizzes?.[quizId]) {
      quiz = {
        ...data.quizzes[quizId],
        type: data.quizzes[quizId].type ?? determineQuizType(data.quizzes[quizId])
      } as QuizProgress;
    } else if (data?.quizProgress) {
      const migratedData = await migrateQuizData(user.uid, data);
      quiz = migratedData[quizId] as QuizProgress || null;
    }

    // Cache if found
    // if (quiz) {
    //   await quizCache.set(cacheKey, quiz, CACHE_CONFIG.quizzes.ttl);
    // }
    return quiz;
  } catch (error) {
    console.error('Error getting active quiz:', error);
    throw error;
  }
};

export const getAllActiveQuizzes = async (
  userId: string
): Promise<QuizProgress[]> => {
  if (!userId) throw new Error('No user ID provided');

  const cacheKey = generateCacheKey.activeQuizzes(userId);
  
  try {
    // Remove cache check temporarily to debug
    // const cachedQuizzes = await quizCache.get<QuizProgress[]>(cacheKey);
    // if (cachedQuizzes) {
    //   return cachedQuizzes;
    // }

    // Fetch fresh data
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    logger.debug('Quiz', 'Fetching active quizzes', {
      userId,
      hasData: !!data,
      hasQuizzes: !!data?.quizzes,
      quizCount: data?.quizzes ? Object.keys(data.quizzes).length : 0
    });

    let quizzes = data?.quizzes;

    if (!quizzes && data?.quizProgress) {
      const migratedData = await migrateQuizData(userId, data);
      quizzes = migratedData;
    }

    if (!quizzes) {
      return [];
    }

    const activeQuizzes = Object.entries(quizzes)
      .map(([quizId, quiz]) => {
        const typedQuiz = quiz as Partial<QuizProgress>;
        const completed = (typedQuiz.currentQuestionIndex ?? 0) >= (typedQuiz.questions?.length ?? 0);
        
        return {
          quizId: Number(quizId),
          currentQuestionIndex: typedQuiz.currentQuestionIndex ?? 0,
          initialQuestionIndex: typedQuiz.initialQuestionIndex ?? 0,
          score: typedQuiz.score ?? 0,
          date: typedQuiz.date ?? new Date().toISOString(),
          lastUpdated: typedQuiz.lastUpdated ?? new Date().toISOString(),
          wrongAnswers: typedQuiz.wrongAnswers ?? [],
          questions: typedQuiz.questions ?? [],
          questionIds: typedQuiz.questionIds ?? typedQuiz.questions?.map(q => q.id) ?? [],
          type: typedQuiz.type ?? determineQuizType(typedQuiz),
          category: typedQuiz.category,
          completed,
          adaptiveState: typedQuiz.adaptiveState
        } as QuizProgress;
      })
      .filter(quiz => !quiz.completed);

    logger.debug('Quiz', 'Processed active quizzes', {
      totalQuizzes: Object.keys(quizzes).length,
      activeQuizzes: activeQuizzes.length,
      firstQuizProgress: activeQuizzes[0] ? {
        currentIndex: activeQuizzes[0].currentQuestionIndex,
        total: activeQuizzes[0].questions.length
      } : null
    });

    // Update cache with fresh data
    await quizCache.set(cacheKey, activeQuizzes, 0); // Set TTL to 0 to effectively disable caching
    return activeQuizzes;
  } catch (error) {
    logger.error('Quiz', 'Error getting active quizzes', {
      error,
      userId
    });
    throw error;
  }
};

// Helper function to shuffle array
const shuffleArray = <T,>(array: T[]): T[] => {
    return [...array].sort(() => Math.random() - 0.5);
  };
  
const migrateQuizProgress = (progress: Partial<QuizProgress>): QuizProgress => {
  const now = new Date().toISOString();
  return {
    ...progress,
    date: progress.date || now, // Original creation date
    lastUpdated: progress.lastUpdated || now, // Last modification time
    type: progress.type || QuizType.SAMPLE,
    completed: progress.completed || false,
    category: progress.category || 'unknown',
    questionIds: progress.questionIds || progress.questions?.map(q => q.id) || [],
    initialQuestionIndex: progress.initialQuestionIndex || 0,
    score: progress.score || 0,
    wrongAnswers: progress.wrongAnswers || [],
    adaptiveState: progress.adaptiveState || {
      currentDifficulty: 5,
      categoryStrengths: {},
      insights: {
        weakCategories: [],
        recommendedDifficulty: 5,
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 90,
          timeManagementAdvice: 'Take your time'
        }
      }
    }
  } as QuizProgress;
};
  