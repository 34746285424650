import React, { Component, ErrorInfo, ReactNode } from 'react';
import { trackError } from '../utils/ErrorTracking';
import { prodLogger } from '../utils/logger';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    trackError(error, {
      type: 'js',
      component: 'ErrorBoundary',
      metadata: errorInfo
    });
  }

  public render() {
    if (this.state.hasError) {
      return <h1>Sorry.. there was an error</h1>;
    }

    return this.props.children;
  }
}

class QuizErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    prodLogger.prodDebug('QuizError', 'Quiz component error', {
      error,
      componentStack: errorInfo.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-red-600">Something went wrong with the quiz. Please try again.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;