import React, { useState } from 'react';
import { User } from '../../models/UserTypes';
import { QuizType } from '../../models/QuizTypes';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../../utils/Analytics';
import { QuizProgress } from '../../utils/quiz/types';
import { QuizInitParams } from '../../hooks/useQuiz';

interface StudyOptionsComponentProps {
  userData: User;
  initializeQuizWithParams: (params: QuizInitParams) => Promise<QuizProgress>;
}

interface TestConfig {
  name: string;
  description: string;
  requiresSubscription: boolean;
  disabled?: boolean;
}

const TEST_TYPES: Record<string, TestConfig> = {
  'lcsw': {
    name: 'LCSW Full Test',
    description: 'Complete LCSW practice exam with 170 questions',
    requiresSubscription: true,
  },
  'lmsw': {
    name: 'LMSW Full Test',
    description: 'Complete LMSW practice exam with 170 questions',
    requiresSubscription: true,
  },
  'lcsw_free': {
    name: 'LCSW Free Quiz',
    description: '30 free LCSW practice questions',
    requiresSubscription: false,
  },
  'lbsw': {
    name: 'LBSW Test (Coming Soon)',
    description: 'LBSW practice exam - Available soon!',
    requiresSubscription: true,
    disabled: true,
  },
};

interface QuizConfirmationModalProps {
  testConfig: TestConfig;
  onConfirm: () => void;
  onClose: () => void;
}

const QuizConfirmationModal: React.FC<QuizConfirmationModalProps> = ({
  testConfig,
  onConfirm,
  onClose,
}) => {
  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-4">
          Start {testConfig.name}?
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-6">
          {testConfig.description}
        </p>
        <div className="flex space-x-3">
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 
                     dark:bg-blue-500 dark:hover:bg-blue-600 text-white"
          >
            Start Test
          </button>
          <button
            onClick={onClose}
            className="flex-1 border border-gray-300 dark:border-gray-600 
                     px-4 py-2 rounded-lg text-gray-700 dark:text-gray-300
                     hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const StudyOptionsComponent: React.FC<StudyOptionsComponentProps> = ({
  userData,
  initializeQuizWithParams,
}) => {
  const [selectedTest, setSelectedTest] = useState<string | null>(null);

  const handleStartQuiz = async (testName: string) => {
    const testConfig = TEST_TYPES[testName];
    
    if (testConfig.requiresSubscription && !userData.isSubscribed) {
      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_TYPE_SELECTED, {
        source: 'study_options',
        quiz_id: 0,
        quiz_type: testName,
      });
      window.location.href = '/pricing';
      return;
    }

    if (testConfig.disabled) {
      return;
    }

    setSelectedTest(testName);
  };

  const handleConfirm = async () => {
    if (!selectedTest) return;

    try {
      await initializeQuizWithParams({
        type: QuizType.FULL,
        testName: selectedTest,
        isSubscribed: userData.isSubscribed
      });

      logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_STARTED, {
        quiz_id: 0,
        quiz_type: selectedTest,
        category: undefined
      });
    } catch (error) {
      console.error('Error starting quiz:', error);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-100">
        Available Tests
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {Object.entries(TEST_TYPES).map(([key, test]) => (
          <div
            key={key}
            className={`p-4 border rounded-lg ${
              test.disabled
                ? 'bg-gray-100 dark:bg-gray-700 cursor-not-allowed'
                : 'hover:border-blue-500 dark:hover:border-blue-400 cursor-pointer'
            }`}
          >
            <h3 className="font-semibold text-lg mb-2 text-gray-900 dark:text-gray-100">
              {test.name}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
              {test.description}
            </p>
            <button
              onClick={() => handleStartQuiz(key)}
              disabled={test.disabled}
              className={`w-full px-4 py-2 rounded-lg text-white ${
                test.disabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : test.requiresSubscription && !userData.isSubscribed
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {test.disabled
                ? 'Coming Soon'
                : test.requiresSubscription && !userData.isSubscribed
                ? 'Subscribe to Access'
                : 'Start Test'}
            </button>
          </div>
        ))}
      </div>

      {selectedTest && (
        <QuizConfirmationModal
          testConfig={TEST_TYPES[selectedTest]}
          onConfirm={handleConfirm}
          onClose={() => setSelectedTest(null)}
        />
      )}
    </div>
  );
};

export default StudyOptionsComponent;