import { AdaptiveMetrics, AdaptiveState, CategoryMetrics } from '@/models/AdaptiveState';
import { AdaptiveLearningEngine } from '../adaptiveLearning';
import { Question, QuizProgress, QuizSummary, QuizType, TestResult } from './types';
import { validateQuestions, shuffleArray } from './validation';
import { QuestionTiming, calculateAverageResponseTime } from './../../components/dashboard/utils';

// Add this type definition at the top of the file
type CategoryBreakdownData = {
  total: number;
  correct: number;
  incorrect: number;
};

export const initializeNewQuiz = (
  questions: Question[], 
  type: QuizType,
  category?: string,
  engine?: AdaptiveLearningEngine | null
): QuizProgress => {
  const validatedQuestions = validateQuestions(questions);
  const shuffledQuestions = shuffleArray(validatedQuestions);
  const cat = category ?? '';

  // Initialize adaptive state with all required fields
  const adaptiveState: AdaptiveState = {
    engine: engine ?? null,
    currentDifficulty: engine?.getCurrentDifficulty() ?? 5,
    categoryStrengths: engine?.getCategoryStrengths() ?? {},
    insights: {
      weakCategories: engine?.getWeakCategories() ?? [],
      recommendedDifficulty: engine?.getRecommendedDifficulty() ?? 5,
      suggestedPracticeFrequency: engine?.getSuggestedPracticeFrequency() ?? 'daily',
      performanceTrend: engine?.getPerformanceTrend() ?? 'steady',
      timeManagement: {
        averageQuestionTime: 0,
        recommendedTimePerQuestion: 90,
        timeManagementAdvice: 'Take your time to read each question carefully'
      }
    },
    recentlyAskedQuestions: [],
    consecutiveCorrect: 0,
    consecutiveIncorrect: 0
  };

  const quizProgress: QuizProgress = {
    quizId: Date.now(),
    currentQuestionIndex: 0,
    initialQuestionIndex: 0,
    score: 0,
    adaptiveState,
    date: new Date().toISOString(),
    wrongAnswers: [],
    questions: shuffledQuestions,
    questionIds: shuffledQuestions.map(q => q.id),
    type: type ?? QuizType.SAMPLE,
    category: cat,
    questionTimes: {},
    completed: false,
    lastUpdated: new Date().toISOString()
  };

  return quizProgress;
};

export const calculateQuizScore = (progress: QuizProgress): number => {
  if (!progress.questions.length) return 0;
  
  const answeredQuestions = progress.questions.filter(q => q.selected_answer !== null);
  const correctAnswers = answeredQuestions.filter(q => 
    q.selected_answer === q.correct_answer
  );
  
  return correctAnswers.length;
};

export const processQuizAnswer = (
  progress: QuizProgress,
  questionIndex: number,
  selectedAnswer: string
): { 
  updatedProgress: QuizProgress,
  isCorrect: boolean 
} => {
  const question = progress.questions[questionIndex];
  if (!question) throw new Error('Invalid question index');

  const isCorrect = selectedAnswer === question.correct_answer;
  const updatedQuestions = progress.questions.map((q, idx) =>
    idx === questionIndex ? { ...q, selected_answer: selectedAnswer } : q
  );

  let updatedWrongAnswers = [...progress.wrongAnswers];
  if (!isCorrect) {
    updatedWrongAnswers.push({
      question,
      explanation: question.explanation || '',
      date: new Date().toISOString(),
      isReviewed: false
    });
  }

  const updatedProgress: QuizProgress = {
    ...progress,
    questions: updatedQuestions,
    wrongAnswers: updatedWrongAnswers,
    score: calculateQuizScore({ ...progress, questions: updatedQuestions })
  };

  return { updatedProgress, isCorrect };
};

export const isQuizComplete = (progress: QuizProgress): boolean => {
  return progress.currentQuestionIndex >= progress.questions.length;
};

export const getQuizSummary = (progress: QuizProgress): QuizSummary => {
  const totalQuestions = progress.questions.length;
  const answeredQuestions = progress.questions.filter(q => q.selected_answer !== null);
  const correctAnswers = answeredQuestions.filter(q => 
    q.selected_answer === q.correct_answer
  );

  const summary: QuizSummary = {
    totalQuestions,
    answeredQuestions: answeredQuestions.length,
    correctAnswers: correctAnswers.length,
    wrongAnswers: progress.wrongAnswers.length,
    score: progress.score,
    completionPercentage: (correctAnswers.length / totalQuestions) * 100,
    categoryBreakdown: getCategoryBreakdown(progress)
  };

  return summary;
};

export const getCategoryBreakdown = (progress: QuizProgress) => {
  return progress.questions.reduce((acc, question) => {
    const category = question.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = { total: 0, correct: 0, incorrect: 0 };
    }
    
    acc[category].total++;
    if (question.selected_answer) {
      if (question.selected_answer === question.correct_answer) {
        acc[category].correct++;
      } else {
        acc[category].incorrect++;
      }
    }
    
    return acc;
  }, {} as Record<string, { total: number; correct: number; incorrect: number; }>);
};

// Add this to track timing for questions
export const startQuestionTimer = (): QuestionTiming => ({
  startTime: Date.now()
});

export const endQuestionTimer = (timing: QuestionTiming): number => {
  timing.endTime = Date.now();
  return (timing.endTime - timing.startTime) / 1000; // Returns seconds
};

export const prepareTestResult = (
  progress: QuizProgress,
  questionTimings?: Record<number, QuestionTiming>
): TestResult => {
  const summary = getQuizSummary(progress);
  const engine = progress.adaptiveState.engine;
  
  // Generate adaptive metrics using the engine if available, otherwise create default metrics
  const adaptiveMetrics: AdaptiveMetrics = engine 
    ? engine.generateAdaptiveMetrics()
    : {
        startingDifficulty: progress.adaptiveState.currentDifficulty,
        endingDifficulty: progress.adaptiveState.currentDifficulty,
        averageResponseTime: calculateAverageResponseTime(questionTimings),
        categoryPerformance: generateCategoryPerformance(progress, questionTimings),
        overallSuccessRate: summary.correctAnswers / summary.totalQuestions,
        totalQuestionsAttempted: summary.totalQuestions,
        currentStreak: progress.adaptiveState.consecutiveCorrect,
        longestStreak: progress.adaptiveState.consecutiveCorrect, // This is a simplification
        lastSessionDate: progress.date,
        weeklyActivityStreak: 1, // Would need historical data for accuracy
        studyTimeThisWeek: 1, // Would need historical data for accuracy
        estimatedExamReadiness: calculateEstimatedReadiness(summary),
        completedCategories: getCompletedCategories(progress),
        mastery: {
          beginner: [],
          intermediate: [],
          advanced: []
        },
        recentPerformance: [{
          date: progress.date,
          score: summary.correctAnswers,
          category: progress.category,
          questionsAttempted: summary.totalQuestions
        }],
        preferredTimes: [],
        difficultyProgression: [{
          date: progress.date,
          difficulty: progress.adaptiveState.currentDifficulty,
          successRate: summary.correctAnswers / summary.totalQuestions
        }]
      };

  let testResult: TestResult = {
    type: progress.type ?? QuizType.SAMPLE,
    id: progress.quizId,
    date: progress.date,
    score: progress.score,
    totalQuestions: summary.totalQuestions,
    percentage: summary.completionPercentage,
    questions: progress.questions,
    wrongAnswers: progress.wrongAnswers,
    adaptiveMetrics,
    category: progress.category
  };

  return testResult;
};

const generateCategoryPerformance = (
  progress: QuizProgress,
  timings?: Record<number, QuestionTiming>
): Record<string, CategoryMetrics> => {
  const categoryMetrics: Record<string, CategoryMetrics> = {};
  
  progress.questions.forEach((question, index) => {
    const category = question.category || 'uncategorized';
    if (!categoryMetrics[category]) {
      categoryMetrics[category] = {
        correct: 0,
        total: 0,
        averageTime: 0,
        successRate: 0
      };
    }
    
    categoryMetrics[category].total++;
    if (question.selected_answer === question.correct_answer) {
      categoryMetrics[category].correct++;
    }
    
    // Add timing data if available
    if (timings?.[index]?.endTime) {
      const timeSpent = (timings[index].endTime! - timings[index].startTime) / 1000;
      const currentTotal = categoryMetrics[category].averageTime * (categoryMetrics[category].total - 1);
      categoryMetrics[category].averageTime = (currentTotal + timeSpent) / categoryMetrics[category].total;
    }
  });
  
  // Calculate success rates
  Object.values(categoryMetrics).forEach(metric => {
    metric.successRate = metric.correct / metric.total;
  });
  
  return categoryMetrics;
};

const calculateEstimatedReadiness = (summary: ReturnType<typeof getQuizSummary>): number => {
  const baseReadiness = summary.completionPercentage / 100;
  const categoryCount = Object.keys(summary.categoryBreakdown).length;
  const categoryFactor = Math.min(categoryCount / 10, 1); // Assumes 10 categories is complete coverage
  
  return Math.round(baseReadiness * categoryFactor * 100);
};

const getCompletedCategories = (progress: QuizProgress): string[] => {
  const categoryBreakdown = getCategoryBreakdown(progress);
  return Object.entries(categoryBreakdown)
    .filter(([_, stats]) => stats.correct / stats.total >= 0.8)
    .map(([category]) => category);
}; 